import axios from "axios";
import React, { Component } from "react";
import {
  BrowserRouter as Router, Redirect, Route,
  Switch
} from "react-router-dom";
import { isArray } from "util";
import "./App.css";
import Loader from "./components/Loader";
import lazyComponentLoader from "./hoc/LazyLoader";
import ProtectedRoute from "./hoc/ProtectedRoute";
import PublicRoute from "./hoc/PublicRoute";
import AuthService from "./services/api/AuthService";
import DialogUtils from "./utils/DialogUtils";
import LayoutView from "./views/layout/LayoutView";

interface IAppState {
  isLoading: Boolean;
  errorVisible: Boolean;
}
const authService = new AuthService();
//AUTH
const Login: any = lazyComponentLoader(
  () => import("./views/auth/login/LoginView")
);
const Forgot: any = lazyComponentLoader(
  () => import("./views/auth/forgot/ForgotView")
);
const Reset: any = lazyComponentLoader(
  () => import("./views/auth/reset/ResetView")
);

//PATIENT MANAGEMENT
const PatientListView: any = lazyComponentLoader(
  () => import("./views/patient/list/PatientListView")
);
const PatientView: any = lazyComponentLoader(
  () => import("./views/patient/view/PatientView")
);

//DOCTOR MANAGEMENT
const DoctorListView: any = lazyComponentLoader(
  () => import("./views/doctor/list/DoctorListView")
);
const DoctorView: any = lazyComponentLoader(
  () => import("./views/doctor/view/DoctorView")
);

//CONDITION MANAGEMENT
const ConditionListView: any = lazyComponentLoader(
  () => import("./views/condition/list/ConditionListView")
);
const ConditionView: any = lazyComponentLoader(
  () => import("./views/condition/view/ConditionView")
)

//TEMPLATE MANAGEMENT
const TemplateListView: any = lazyComponentLoader(
  () => import('./views/templates/list/TemplateListView')
)
const TemplateView: any = lazyComponentLoader(
  () => import('./views/templates/view/TemplateView')
)

//SETTINGS MANAGEMENT
const SettingsView: any = lazyComponentLoader(
  () => import('./views/settings/view/SettingsView')
)

class App extends Component<any, IAppState> {
  constructor(props: any) {
    super(props);
    //initialize state here
    this.state = {
      isLoading: false,
      errorVisible: true,
    };

    const self = this;
    axios.interceptors.request.use(
      function (config) {
        const url = config.url;
        const endPoint = (url || "/").split("/")[
          (url || "").split("/").length - 2
        ];
        if (endPoint === "phone_validation" || endPoint === "name_validation") {
          self.setState({ isLoading: false });
        } else {
          self.setState({ isLoading: true });
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        // spinning hide
        self.setState({ isLoading: false });

        return response;
      },
      async (error) => {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.clear();
            return <Redirect from="*" to="/login" />;
          } else {
            if (error.response) {
              const refreshToken = `"refresh_token" must be a string`;
              const accessToken = `"access_token" must be a string`;
              if (
                error.response.data.errors.length > 0 &&
                error.response.data.errors.some(
                  (e: any) =>
                    e.message === refreshToken || e.message === accessToken
                )
              ) {
                localStorage.clear();
                <Redirect from="*" to="/login" />;
              } else {
                this.showError(error.response.data.errors);
              }
            } else {
              this.showError(error);
            }
            self.setState({ isLoading: false });
            return Promise.reject(error);
          }
        }
      }
    );
  }

  showError(errors: any) {
    let content: any = null;
    if (isArray(errors)) {
      // message.error(
      content = (
        <div>
          {errors.map((item: any, index: number) => {
            return <div key={Math.random().toString(6)}>{item.message}</div>;
          })}
        </div>
      );
      // );
    } else {
      content =
        errors === undefined || errors.message.includes("Network Error")
          ? "No network connection. Make sure that WI-FI or Cellular Mobile Data is turned on, then try again."
          : errors.message;
    }
    // const err = content? content.split('Error: ') : '';
    DialogUtils.error(content, ()=>{});
  }

  render() {
    return (
      <div>
        <Router>
          <Switch>
            <PublicRoute
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact
              path="/login"
              component={Login}
            />
            <PublicRoute
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact
              path="/reset-password"
              component={Reset}
            />
            <PublicRoute
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact
              path="/change-password/:token"
              component={Reset}
            />
            <PublicRoute
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact
              path="/set-password/:token"
              component={Reset}
            />
            <PublicRoute
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact
              path="/forgot-password"
              component={Forgot}
            />
            <LayoutView>
              {/* patients */}
            <ProtectedRoute exact path={'/patients'} component={PatientListView} />
            <ProtectedRoute exact path={'/patients/:id'} component={PatientView} />
            {/* doctor */}
            <ProtectedRoute exact path={'/doctors'} component={DoctorListView} />
            <ProtectedRoute exact path={'/doctors/:id'} component={DoctorView} />
            {/* conditions */}
            <ProtectedRoute exact path={'/conditions'} component={ConditionListView} />
            <ProtectedRoute exact path={'/conditions/:id'} component={ConditionView} />
            {/* templates */}
            <ProtectedRoute exact path={'/templates'} component={TemplateListView} />
            <ProtectedRoute exact path={'/settings'} component={SettingsView} />
            <ProtectedRoute exact path={'/templates/:id'} component={TemplateView} />
              <Route
                render={(props: any) => {
                  const { key } = props.location;
                  const isLoggedIn = authService.isLoggedIn();
                  const access_token = localStorage.getItem("access_token");

                  if (isLoggedIn && !key) {
                    return <Redirect from="*" to="/patients" />;
                  } else if (!isLoggedIn) {
                    return <Redirect from="*" to="/login" />;
                  }

                  if (!access_token) {
                    localStorage.clear();
                    return <Redirect from="*" to="/login" />;
                  }
                }}
              />
            </LayoutView>
          </Switch>
        </Router>

        {this.state.isLoading ? <Loader /> : null}
      </div>
    );
  }
}

export default App;
