import React, { useEffect, useState } from 'react';
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { Row, Col, Dropdown, Menu, Badge, Divider } from 'antd';
import { BurgerIcon, GearIcon, NotificationIcon } from '../../../../components/icons/Icons';

import './HeaderView.less';
import DynamicDialog from '../../../../components/DynamicDialog';
import AuthService from '../../../../services/api/AuthService';

import APPConfig from '../../../../config'
import APITokens from '../../../../services/api/APITokens';

const HeaderView = (props: any) => {
    const { history } = props
    const authService = new AuthService();

    const [user, setUser]: any = useState();
    const [isVisible, setIsVisible] = useState(false)
    const [dialogContent, setDialogContent] = useState({})
    const [modalClassname, setModalClassname]: any = useState()
    const [isReadAllNotif, setIsReadAllNotif]: any = useState()
    const [notifsDataCount, setNotifsDataCount]: any = useState([])

    useEffect(() => {


        if (!localStorage.getItem('user')) {
            // onLogout();
            localStorage.clear();
            history.push('/login');
        } else {
            const data: any = localStorage.getItem('user');
            setUser(JSON.parse(data));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleAllNotifications = () => {
        props.history.push('/notifications')
        setIsVisible(false)
    }


    const onLogout = async () => {
      const tokens: object | any = APITokens.getLocalTokens() || {};

      try {
        await authService.logout(tokens.access_token, (tokens.refresh_token || 'temp'));
        localStorage.removeItem('assigned_user');
        localStorage.clear();
        history.push('/login');
      } catch (err) {
        localStorage.clear();
        history.push('/login');
      }
    }

    const toggleSidebar = () => {
        props.collapseToggle(!props.siderCollapsed)
    }

    const customFooter = (content: string) => (
        <Row justify='center' align='middle' style={{ padding: 10 }}>
            <span style={{ cursor: 'pointer', font: 'normal normal 600 14px/18px Poppins', color: '#00AD4C' }}
                onClick={() => console.log('Route to Notificaton Page')}
            >{content}</span>
        </Row>
    )



    const menu = (
        <Menu className='header-submenu'>
            <Menu.Item key="1" className="link-secondary" onClick={() => history.push('/settings')} >
                <Row align='middle' justify='space-between' className="c-pointer fw-500 text-secondary">
                    <span>Settings</span>
                    <SettingOutlined />
                </Row>
            </Menu.Item>
            <Divider />
            <Menu.Item key="2" className="link-secondary" onClick={() => onLogout()}>
                <Row align='middle' justify='space-between' className="c-pointer fw-500 text-secondary">
                    <span>Log Out</span>
                    <LogoutOutlined />
                </Row>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <DynamicDialog
                bodyStyle={{ overflowY: 'scroll', height: 500 }}
                visibility={isVisible}
                content={dialogContent}
                closable={false}
                className={modalClassname}
                onSetVisibility={(value: boolean) => {
                    setIsVisible(value)
                }}
            />
            <Row justify="space-between" align="middle" className="padding-m">
                <Col>
                    <BurgerIcon className="c-pointer" onClick={toggleSidebar} />
                </Col>
                <Col className="visible-xs"><h2 className="text-secondary r-margin mobile-header">{props.pageHeader}</h2></Col>
                <Col>
                    <Row align="middle" gutter={[12, 0]} className='header-rightIcon'>
                        <Col>
                            <Dropdown overlay={menu} trigger={['click']} className="c-pointer hidden-xs" placement="bottomLeft" overlayClassName='ant-dropdown-menu-settings'>
                                <div className="icon-container">
                                    <GearIcon />
                                </div>
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default withRouter(HeaderView)