import React from "react";
import { Button, Typography } from "antd";
import { CloseCircleFilled, CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
//images
import {ReactComponent as CheckCircle} from './images/svg/checkCircle.svg'
import {ReactComponent as CloseCircle} from './images/svg/closeCircle.svg'
//style
import './component.less'
const ERROR_COLOR = "#0A1339";
const SUCCESS_COLOR = "#9A6AFE";

const iconStyle = { fontSize: 65, borderRadius: 68 };
const titleStyle = { textAlign: 'center'}

interface ModalContentProps {
  title?: string;
  content: HTMLElement;
  type: "success" | "error" | "confirm";
}

const { Title } = Typography;

const ModalError = () => {
  return (
    <>
      <div id="icon-container">
        <div className="modal-logo-container">
          <CloseCircle/>
        </div>
      </div>
    </>
  );
};

const ModalSuccess = () => {
    return (
      <>
        <div id="icon-container">
         <div className="modal-logo-container">
            <CheckCircle/>
          </div>
        </div>
      </>
    );
  };

  const ModalConfirm = () => {
    return (
      <>
        <div id="icon-container">
          <div className="modal-logo-container">
            <ExclamationCircleFilled
              style={{ ...iconStyle, color: SUCCESS_COLOR }}
            />
          </div>
        </div>
      </>
    );
  };

const ModalContent = (props: ModalContentProps) => {
  let RenderModalType;
  switch (props.type) {
    case "error":
      RenderModalType = ModalError;
      break;
    case "success":
      RenderModalType = ModalSuccess;
      break;
    case "confirm":
      RenderModalType = ModalConfirm;
        break;
  }
  return (
    <div>
      {RenderModalType && <RenderModalType />}
      <div className="modal-container">
        <text className="head-text">
          {props.title && props.title}
        </text>
        <text className="info-text">
          {props.content}
        </text>
      </div>
    </div>
  );
};

export default ModalContent;
