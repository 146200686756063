import React, { useEffect, useState } from 'react';
import { Col, Dropdown, Menu, Row, Space, List, Avatar } from 'antd';
import {useHistory, useLocation } from 'react-router-dom';
import { UserOutlined, CustomerServiceFilled, LogoutOutlined, ToolOutlined } from '@ant-design/icons';
// MachineIcon
import { CustomersIcon, DoctorIcon, ConditionIcon, TemplateIcon } from '../../../../components/icons/Icons';
import AuthService from '../../../../services/api/AuthService';
//images
import {ReactComponent as LogoNameSidebar} from './images/svg/logoNameSidebar.svg';
import LogoIcon from './images/png/Logo.png'
import {ReactComponent as AdminIcon }from './images/svg/adminIcon.svg'

import './SideMenuView.less';

const getUser = () => {
  const data:any = localStorage.getItem('user');
  return data
}

const SideMenuView = ({siderCollapsed, collapseToggle}:any) => {   
  const location = useLocation();
  const history = useHistory();
  const [user, setUser]:any = useState();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const auth = new AuthService()

  const data = JSON.parse(getUser())
  useEffect(() => {
    const keyPath = keyPathTransformer(`/${location.pathname.split('/')[1]}`)
    setCurrentPath(keyPath);
    // verifyUser()
  }, [location.pathname]);

  const verifyUser = async () => {
    if(data){
      const isDeleted = await auth.verifyUser(data._id)
      if(isDeleted) onLogout();
    }
  }

  useEffect(() => {
    if(!data) {
        onLogout();
    } 
  }, []);

  const keyPathTransformer = (value: string) => {
    switch (value) {
      case '/patients': {
        return '/patients'
      }
      case '/doctors': {
        return '/doctors'
      }
      case '/conditions': {
        return '/conditions'
      }
      case '/templates': {
        return '/templates'
      }
      default: {
        return '/patients'
      }
    }
  }


  const onLogout = () => {
    localStorage.clear();
    history.push('/login');
  }

  const profile = () => {
    history.push('/profile');
  }

  const onToggleMenus = (action:string) => {
    if(window.innerWidth <= 768) {
      collapseToggle(!siderCollapsed);
    }
    setCurrentPath(action);
    history.push(action);
  }

  const menu = (
    <Menu>
      <Menu.Item key="0" className="link-secondary">
        <span onClick={() => profile()} className="c-pointer fw-600">
          <UserOutlined /> My Profile
        </span>
      </Menu.Item>
      <Menu.Item key="2" className="link-secondary">
        <span onClick={() => onLogout()} className="c-pointer fw-600">
          <LogoutOutlined/> Log Out
        </span>
      </Menu.Item>
    </Menu>
  );

  const profileInfo = [{
    name: data ? data.name : null,
    type: data ? data.user_type : null,
    image: <UserOutlined />
  }]

   return (
        <Row className="sidebar-root">
          <div className='sidebar-logo'>
            {siderCollapsed ? (
              <img src={LogoIcon} className="logo-icon" alt="logo" />
            ):(
              <LogoNameSidebar className='logo-text'/>
            )}
              
          </div>
          <Menu
            mode="inline"
            selectedKeys={[currentPath]}
            defaultSelectedKeys={[currentPath === '/' ? '/dashboard' : currentPath ]}
            style={{padding: '0 16px', color: '#1C1243', border: 0}}
          >
            <Menu.Item   key="/patients" onClick={() => onToggleMenus('/patients')}>
              <CustomersIcon size={30} />
                <span className='side-menu-title'>Patients</span>
            </Menu.Item>
            <Menu.Item   key="/doctors" onClick={() => onToggleMenus('/doctors')}>
              <DoctorIcon size={24} />
                <span className='side-menu-title'>Doctors</span>
            </Menu.Item>
            <Menu.Item   key="/conditions" onClick={() => onToggleMenus('/conditions')}>
              <ConditionIcon size={24} />
                <span className='side-menu-title'>Conditions</span>
            </Menu.Item>
            <Menu.Item   key="/templates" onClick={() => onToggleMenus('/templates')}>
              <TemplateIcon size={24} />
                <span className='side-menu-title'>Templates</span>
            </Menu.Item>
          </Menu>
          <Row className="profile-info">
              <List
                className="hidden-xs"
                itemLayout="horizontal"
                dataSource={profileInfo}
                renderItem={info => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar shape="square" size={54} icon={<AdminIcon/>} />}
                      title={info.name}
                      description={`${info.type ? info.type.charAt(0).toUpperCase() + info.type.slice(1) : ''}`}
                    />
                  </List.Item>
                )}
              />
              
              
            </Row>
            {!siderCollapsed ? (<div className="sidebar--overlay" onClick={() => collapseToggle(!siderCollapsed)}></div>) : null}
        </Row>
    );
}

export default SideMenuView