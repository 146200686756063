import React from 'react'
import { Modal } from 'antd'


const DynamicDialog = ({ visibility = false, onSetVisibility, content, className }: any) => {
  const { title, component, footer = null, width = 770, height = 550 } = content
  return (
    <Modal
      centered
      width={width}
      title={title}
      footer={footer}
      visible={visibility}
      destroyOnClose={true}
      style={{ color: '#014050', maxHeight: 900 }}
      bodyStyle={{ height, overflowY: 'auto' }}
      className={className}
      onCancel={() => onSetVisibility(false)}
    >
      <div>
        {component}
      </div>
    </Modal>
  )
}


export default DynamicDialog
