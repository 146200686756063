import React from 'react';
import { Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ModalContent from '../components/ModalContent';

export default class DialogUtils {

    /**
     * 
     * @param content 
     * @param onOk 
     * @param onCancel 
     */
    static confirm(content:any, onOk:Function, onCancel?:Function, okText?:string, cancelText?:string) {
        Modal.confirm({
            icon: null,
            centered: true,
            width: 330,
            className: 'success-modal',
            okButtonProps: {
                id: 'modal-success-btn',
                style: { width: "266px", marginBottom: "10px", }
            },
            cancelButtonProps: {
                id: 'modal-cancel-btn',
                style: { width: "266px", marginBottom: "10px", }
            },
            content: <ModalContent title='' type={'confirm'} content={content} />,
            onOk: () => { if(onOk) onOk() },
            onCancel: () => { if(onCancel) onCancel() },
          });
    }

    
    /**
     * 
     * @param content 
     * @param onOk 
     */
    static error(content:any, onOk:Function) {
        Modal.error({
            icon: null,
            centered: true,
            width: 330,
            className: 'error-modal',
            okButtonProps: {
                id: 'modal-error-btn',
                style: { width: "266px", marginBottom: "10px", backgroundColor: "#f23538", borderColor: "#f23538" }
            },
            content: <ModalContent title='Error!' type={'error'} content={content} />,
            onOk: () => { if(onOk) onOk() }
          });
    }


    /**
     * 
     * @param content 
     * @param onOk 
     */
    static success(content:any, onOk?:Function) {
        Modal.success({
            icon: null,
            centered: true,
            width: 330,
            className: 'success-modal',
            okButtonProps: {
                id: 'modal-success-btn',
                style: { width: "266px", marginBottom: "10px" }
            },
            content: <ModalContent title='Success!' type={'success'} content={content} />,
            onOk: () => { if(onOk) onOk() }
          });
    }
}